<template>
  <div class="bg p-3">
    <h3>Room</h3>
      Room Id: {{ roomId }}<br/>
      Room: {{ room | jsonstringify }}<br/>
      <div v-if="room">
        Select user:
        <div v-for="u in room.users" v-bind:key="u">
          <input type="radio" :id="u" :value="u" v-model="yourId"/>&nbsp;
          <label :for="u">{{ u }}</label>
        </div>
      </div>
    <h3>User</h3>
    <p class="mt-3">
      User Id: {{ yourId }}<br/>
    </p>
    <h3>Streams</h3>
    <div class="mt-3" v-for="n in peers.length" v-bind:key="n">
      <p>Stream {{n}}:</p>
      <video ref="otherMedias" autoplay playsinline></video>
    </div>
    <h3>Peers</h3>
    <div class="mt-3" v-for="p in peers" v-bind:key="p.userId">
      <p>
      Peer user Id: {{ p.userId }} ({{ p.roomUserIndex }})<br/>
      Initiator: {{ p.initiator }}<br/>
      sent: {{ p.messageSent }}<br/>
      received: {{ p.messageReceived }}<br/>
      </p>
      <p>Outgoing: <small>{{ p.outgoing }}</small></p>
      <p>Incoming: <small>{{ p.incoming }}</small></p>
    </div>
  </div>
</template>

<script>
import { db, database } from '@/services/db';
import { getLog } from "@/services/log";
import Peer from "simple-peer";
let log = getLog('test');

export default {
  name: 'app',
  data() {
    return {
      // Room
      roomId: "ya5Uq2Ux7KRWwH9ZNo2d",
      room: null,

      // Peer Connections
      yourId: "",
      yourStream: null,
      rootDb: null,
      peers: [],

      // Streams for display purposes
      streams: [],
    }
  },
  mounted() {
    this.loadRoom(this.roomId);
  },
  beforeDestroy() {
    this.destroyPeerConnections();
  },
  watch: {
    yourId() {
      this.destroyPeerConnections();
      this.createPeerConnections();
    }
  },
  methods: {
    // Room
    loadRoom(roomId) {
      this.$bind("room", db.collection("LiveRooms").doc(roomId)).then((room) => {
        log.log("Room loaded", room);
        this.room = room;
      });
    },
    updatePeers() {
      this.peers.reverse();
      this.peers.reverse();
    },
    updateStream(index, stream) {
      this.streams[index] = stream;
      for (let i in this.streams)
        this.$refs.otherMedias[i].srcObject = this.streams[i];
    },
    createPeer(i, peerIndex) {
      let that = this;
      let userIndex = this.room.users.findIndex((u) => u == that.yourId);

      // Initiator is i above userIndex, otherwise initiated by other.
      let initiator = (userIndex < i);

      // Peer
      let p = new Peer({
        initiator: initiator,
        trickle: false,
        objectMode: false,
      });
      p.userId = this.room.users[i];
      p.roomUserIndex = i;
      p.peerIndex = peerIndex;
      p.messages = [];
      log.log("Creating peer", p);

      p.on('error', err => {
        log.log('error', err)
      });

      p.on('signal', data => {
        log.log('SIGNAL', data)
        p.outgoing = JSON.stringify(data);
        that.updatePeers();
        that.sendMessage(p, that.yourId, p.userId, p.outgoing);
      });

      p.on('connect', () => {
        p.messageSent = 'whatever' + Math.random();
        that.updatePeers();
        log.log('CONNECT', p.messageSent);
        p.send(p.messageSent);
      });

      p.on('data', data => {
        p.messageReceived = data;
        that.updatePeers();
        log.log('data: ', data)
      });

      p.on("stream", stream => {
        log.log("stream: ", peerIndex, stream);
        that.updateStream(peerIndex, stream);
      });

      p.on("close", () => {
        log.log("close: ", p);
        if (!p.isBeingDestroyed) {
          that.destroyPeer(p);
          that.peers[peerIndex] = that.createPeer(i, peerIndex);
        }
      });

      if (this.yourStream) {
        log.log("addStream", this.yourStream);
        p.addStream(this.yourStream);
      }

      return p;
    },
    destroyPeer(p) {
      log.log("destroyPeer", p)
      this.updateStream(p.peerIndex, null);
      if (p.stream)
        p.stream.getTracks().forEach(track => track.stop());
      for (let m of p.messages)
        m.remove();
      p.isBeingDestroyed = true;
      p.destroy();
    },
    createPeerConnections() {
      log.log("createPeerConnections");
      let that = this;
      this.rootDb = database.ref(`LiveCalls/${this.roomId}`);

      let userIndex = this.room.users.findIndex((u) => u == that.yourId);
      log.log(`Creates ${this.room.users.length - 1} peer connections, userIndex = ${userIndex}`);

      for (let i = 0; i < this.room.users.length; ++i)
      {
        // Skip current user
        if (i == userIndex)
          continue;

        // Create peer
        let p = this.createPeer(i, this.peers.length);
        this.peers.push(p);
      }

      // window destruction
      window.onbeforeunload = () => {
        that.destroyPeerConnections();
      }

      // load content
      navigator.getUserMedia({ video: true, audio: true }, 
        (stream) => {
          log.log("Adding user stream to peers", stream);
          that.yourStream = stream;
          for (let p of that.peers)
            p.addStream(stream);
        }, 
        () => { log.log("getUserMedia error"); }
      );

      // On message on firebase
      this.rootDb.on('child_added', (data) => {
        let msg = JSON.parse(data.val().message);
        let receiver = data.val().receiver;
        let sender = data.val().sender;
        if (receiver == that.yourId) {
          log.log("New message for me", data.key, data.val());
          that.rootDb.child(data.key).remove();
          let p = that.peers.find((peer) => peer.userId == sender);
          log.log("From peer", p)
          if (p) {
            p.incoming = JSON.stringify(msg);
            that.updatePeers();
            p.signal(JSON.parse(p.incoming));
          }
        }
      });
    },
    destroyPeerConnections() {
      log.log("destroyPeerConnections()");

      // Leave database
      if (this.rootDb) {
        this.rootDb.off("child_added");
        this.rootDb = null;
      }

      // Clear my media
      if (this.yourStream) {
        this.yourStream.getTracks().forEach(track => track.stop());
        this.yourStream = null;
      }

      // Clean peers
      for (let p of this.peers) {
        this.destroyPeer(p);
      }
      this.peers = [];
    },
    sendMessage(p, senderId, receiverId, data) {
      log.log(`sendMessage sender: ${senderId} receiver: ${receiverId}`, data);
      p.messages.push(this.rootDb.push({ sender: senderId, receiver: receiverId, message: data }));
    }
  }
}
</script>

<style scoped>

.bg {
  background-color: bisque;
}

textarea {
  width: 600px;
  height: 600px;
}

video {
  width: 500px;
}

</style>
